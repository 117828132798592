import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItemCheckbox,
} from 'components/Dropdown'

const zones = [2, 3, 4, 5, 6, 7, 8].map((zone) => ({
  id: String(zone),
  label: `Zone ${zone}`,
}))

const ZoneFilterButton = ({ filter }: { filter: FilterType }) => {
  return (
    <Dropdown>
      <DropdownToggle isFilter hasValue={filter.values.length != 0}>
        {filter.values.length === 0 ? (
          <span>Zone</span>
        ) : filter.values.length === 1 ? (
          <span>Zone {filter.values[0]}</span>
        ) : (
          <span>Zones {filter.values.join(',')}</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {zones.map((zone) => {
          return (
            <DropdownItemCheckbox
              id={zone.id}
              key={zone.id}
              onClick={filter.toggle}
              isChecked={filter.has(zone.id)}
            >
              {zone.label}
            </DropdownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ZoneFilterButton
