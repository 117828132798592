import { useCallback } from 'react'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import {
  Popover,
  PopoverItem,
  PopoverPopup,
  PopoverToggle,
} from 'components/Popover'

const ScheduledForFilterButton = ({ filter }) => {
  const fromDate = filter.values.length >= 1 ? filter.values[0] : null
  const toDate = filter.values.length >= 2 ? filter.values[1] : null

  const hasValue = fromDate != null || toDate != null

  const handleChangeDateFrom = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filter.setValues([e.currentTarget.value, toDate])
    },
    [filter, toDate],
  )

  const handleChangeDateTo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filter.setValues([fromDate, e.currentTarget.value])
    },
    [filter, fromDate],
  )

  return (
    <Popover>
      <PopoverToggle isFilter caret hasValue={hasValue}>
        Scheduled
      </PopoverToggle>
      <PopoverPopup>
        <PopoverItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </PopoverItem>
        <Form className="m-3" action="" method="post">
          <FormGroup>
            <Label htmlFor="input-date-from" className="pr-1">
              From
            </Label>
            <Input
              name="from"
              onChange={handleChangeDateFrom}
              value={fromDate || ''}
              type="date"
              id="input-date-from"
              placeholder=""
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="input-date-to" className="pr-1">
              To
            </Label>
            <Input
              name="to"
              onChange={handleChangeDateTo}
              value={toDate || ''}
              type="date"
              id="input-date-to"
              placeholder=""
              required
            />
          </FormGroup>
        </Form>
      </PopoverPopup>
    </Popover>
  )
}

export default ScheduledForFilterButton
