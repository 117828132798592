import { LoadingImage } from 'components/Loading'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'
import { askQuestionWithReason } from 'utils/alert'

type Props = {
  selectedRows: string[]
  onModified: () => void
}

const OrderActionButton = (props: Props) => {
  const { onModified, selectedRows } = props

  const cancelApi = useApi(
    () => ({ action: 'dropshipOrders_cancel' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickCancel = useCallback(async () => {
    const reason = await askQuestionWithReason(
      'Are you sure you want to cancel this dropship order?',
    )

    if (!reason) {
      return
    }

    await cancelApi.performRequest({
      json: { id: selectedRows[0], reason },
    })
    onModified()
  }, [onModified, cancelApi, selectedRows])

  const disconnectApi = useApi(
    () => ({ action: 'dropshipOrders_disconnect' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickDisconnect = useCallback(async () => {
    const reason = await askQuestionWithReason(
      'Are you sure you want to disconnect this dropship order?',
    )

    if (!reason) {
      return
    }

    await disconnectApi.performRequest({
      json: { id: selectedRows[0], reason },
    })
    onModified()
  }, [onModified, disconnectApi, selectedRows])

  const isLoading = cancelApi.isLoading

  return (
    <Dropdown>
      <DropdownToggle caret>
        Order {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          disabled={selectedRows.length !== 1}
          title="Cancel selected order"
          onClick={handleClickCancel}
        >
          Cancel
        </DropdownItem>
        <DropdownItem
          disabled={selectedRows.length !== 1}
          title="Disconnect selected dropship order from TTC order"
          onClick={handleClickDisconnect}
        >
          Disconnect
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default OrderActionButton
