import { Popover } from '@base-ui-components/react/popover'
import styles from 'components/Dropdown/index.module.css'

export const PopoverToggle = ({
  children,
  hasValue,
  isFilter,
  disabled,
  onClick,
  caret,
}: {
  children: React.ReactNode
  isFilter?: boolean
  hasValue?: boolean
  disabled?: boolean
  caret?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <Popover.Trigger
      {...{ disabled, onClick }}
      className={styles.Button}
      data-has-value={hasValue ? 'hasValue' : null}
      data-is-filter={isFilter ? '1' : null}
      data-show-caret={caret !== false ? '1' : null}
    >
      {children}
    </Popover.Trigger>
  )
}

export default PopoverToggle
