import { Menu } from '@base-ui-components/react/menu'
import type { Product } from '@ttc/api/products'
import type { PurchaseOrderTruck } from '@ttc/api/purchaseOrders'
import type { Zone, ZonesGetAllResponse } from '@ttc/api/zones'
import { apiRequest, openPrintWindow } from 'api'
import { CellProductName, LinkButton, Loading, LoadingImage } from 'components'
import {
  Table,
  defaultCellRenderer,
  defaultHeaderLabelRenderer,
  setCellLoading,
  setEditCell,
  setRow,
  setTable,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import type { TableHeaderRenderProps } from 'components/Table/TableHeader'
import type {
  TableCellChangeEventProps,
  TableCellRenderProps,
} from 'components/Table/types'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import {
  type ApiCall,
  useApi,
  useBoolean,
  usePanelControl,
  usePrevious,
} from 'hooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { DropdownButton, DropdownItem, DropdownMenu } from 'components/Dropdown'
import { askQuestion, showError, uiApiRequest } from 'utils'
import { getStorage } from 'utils/storage'
import PutawayModal from './PutawayModal'
import TableFooter from './TableFooter'
import ZoneButton from './ZoneButton'
import ZoneCell from './ZoneCell'
import { getColumnDef } from './columnDef'

const storageKey = 'truckItemsTable'
const [tableSetItem, tableGetItem] = getStorage(storageKey)

const EMPTY_OBJECT = {}

type TruckItemsTableProps = {
  id: number
  poId: number
  reloadKey: number
  onChangeTruck: () => void
  handleClickProductName: (props: any) => void
  apiLoadZones: ApiCall<ZonesGetAllResponse>
  readOnly: boolean
}

export type EditProductZoneQty = {
  product: Product
  zoneQty: { [zone: string]: number }
}

const TruckItemsTable = (props: TruckItemsTableProps) => {
  const {
    id,
    poId,
    reloadKey,
    onChangeTruck,
    handleClickProductName,
    apiLoadZones,
    readOnly,
  } = props
  const showCheckinAllZones = useBoolean(false)
  const showLoadTruckZones = useBoolean(false)
  const showFiltered = useBoolean(false, `${storageKey}.showFiltered`)

  const getTruck = useApi<PurchaseOrderTruck>(
    useMemo(() => ({ action: 'purchaseOrders_getTruck', id }), [id]),
    EMPTY_OBJECT,
    { autoPerform: id != null, cache: true },
  )

  const truck = getTruck.result

  const isInternal = truck?.source_location_id != null

  const columnDef = useMemo(
    () => getColumnDef(readOnly, isInternal),
    [readOnly, isInternal],
  )

  const apiAction = useApi(
    () => ({}),
    null,
    () => ({ errorModal: true }),
  )

  const [tableState, tableDispatch] = useTable({
    cacheKey: `truckItemsTable_${props.id}`,
    getItem: tableGetItem,
    setItem: tableSetItem,
  })
  const [tableTriggerSearch] = useTableApi(
    'purchaseOrders_getTruckItems',
    tableState,
    tableDispatch,
    { id: poId, truck_id: id },
    { autoReloadInterval: false },
  )

  const isLoading =
    (getTruck.isLoading && !getTruck.hasResult) || !tableState.didLoad

  const filteredRows = useMemo(
    () => tableState.rows.filter((row) => row.eqty > 0),
    [tableState.rows],
  )

  const rows = showFiltered.value ? filteredRows : tableState.rows

  const [editProductZoneQty, setEditProductZoneQty] =
    useState<EditProductZoneQty>()

  const handleClickChangePutaway = useCallback(
    (product: Product, zoneQty: { [zone: string]: number }) => {
      setEditProductZoneQty({ product, zoneQty })

      tableTriggerSearch()
      getTruck.performRequest()
    },
    [tableTriggerSearch, getTruck],
  )

  const handleClosePutawayModal = useCallback(() => {
    setEditProductZoneQty(null)
    tableTriggerSearch()
    getTruck.performRequest()
  }, [tableTriggerSearch, getTruck])

  const handleClickPutaway = useCallback(async () => {
    await apiAction.performRequest({
      action: 'purchaseOrderTrucks_readyForPutaway',
      json: { id },
    })

    if (onChangeTruck) {
      onChangeTruck()
    }
  }, [id, onChangeTruck, apiAction])

  const handleClickCheckin = useCallback(async () => {
    showCheckinAllZones.toggle()
  }, [showCheckinAllZones])

  const handleClickCheckinZone = useCallback(
    async (zone: Zone) => {
      if (
        !(await askQuestion(
          `This will check in all items of truck ${truck.number} to zone ${zone.name}. Are you sure?`,
        ))
      ) {
        return
      }

      await apiAction.performRequest({
        action: 'purchaseOrderTrucks_checkinAll',
        json: { id: truck.id, zoneId: zone.id },
      })

      showCheckinAllZones.toggle()

      if (onChangeTruck) {
        onChangeTruck()
      }
    },
    [truck, onChangeTruck, apiAction, showCheckinAllZones],
  )

  const handleClickLoadTruck = useCallback(async () => {
    showLoadTruckZones.toggle()
  }, [showLoadTruckZones])

  const handleClickLoadTruckZone = useCallback(
    async (zone: Zone) => {
      if (
        !(await askQuestion(
          `This will load all items from zone ${zone.name} into truck ${truck.number}. Are you sure?`,
        ))
      ) {
        return
      }

      await apiAction.performRequest({
        action: 'purchaseOrderTrucks_loadTruckFromZone',
        json: { id: truck.id, sourceZoneId: zone.id },
      })

      showLoadTruckZones.toggle()

      if (onChangeTruck) {
        onChangeTruck()
      }
    },
    [truck, onChangeTruck, apiAction, showLoadTruckZones],
  )

  const handleClickPrintTags1 = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_purchaseOrders_printTags',
      print: '1',
      id: String(id),
      qtyColumn: 'eqty',
    })
  }, [id])

  const handleClickPrintTags2 = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_purchaseOrders_printTags',
      print: '1',
      id: String(id),
      qtyColumn: 'aqty',
    })
  }, [id])

  const handleClickPrintManifest = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_purchaseOrderTrucks_printManifest',
      print: '1',
      id: String(id),
    })
  }, [id])

  const handleClickPrintOverview = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_purchaseOrderTrucks_printOverview',
      print: '1',
      id: String(id),
    })
  }, [id])

  const dropId = useRef<number>(null)

  const beginDrag = useCallback(() => {
    dropId.current = null
  }, [])

  const didDrop = useCallback(() => {
    const saveRowOrder = async () => {
      try {
        const _dropId = Math.random()
        dropId.current = _dropId

        const table = await apiRequest({
          action: 'purchaseOrders_setItemsOrder',
          json: {
            id: poId,
            truck_id: id,
            sorted_ids: tableState.rows.map((row) => row.id),
          },
        })

        if (dropId.current === _dropId) {
          tableDispatch(setTable(table))
        }
      } catch (e) {
        showError(e.message)
        tableTriggerSearch(true)
      }
    }

    saveRowOrder()
  }, [id, poId, tableState.rows, tableTriggerSearch, tableDispatch])

  const prevReloadKey = usePrevious(reloadKey)
  useEffect(() => {
    if (id == null || reloadKey === prevReloadKey || prevReloadKey == null) {
      return
    }

    tableTriggerSearch()
    getTruck.performRequest()
  }, [id, tableTriggerSearch, prevReloadKey, reloadKey, getTruck])

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      tableDispatch(setEditCell(rowId, colId))
      return false
    },
    [tableDispatch],
  )

  const handleChangeCell = useCallback(
    async (props: TableCellChangeEventProps) => {
      const { value, oldValue, colId, rowId } = props

      if (truck.status === 'COMPLETE' || truck.status === 'DOCK') {
        if (
          !(await askQuestion(
            `Truck status has already status ${truck.status}. Are you sure you want to modify this value?`,
          ))
        ) {
          return
        }
      }

      const apiCall = {
        eqty: 'purchaseOrders_setTruckItemEQty',
        aqty: 'purchaseOrders_setTruckItemAQty',
      }

      if (apiCall[colId] == null) {
        return
      }

      try {
        tableDispatch(setCellLoading(rowId, colId))

        const row = await apiRequest({
          action: apiCall[colId],
          id: rowId,
          value,
          oldValue,
          truck_id: id,
        })

        tableDispatch(setRow(rowId, row))
        tableDispatch(setCellLoading(null))
        tableTriggerSearch(true)

        if (onChangeTruck) {
          onChangeTruck()
        }
      } catch (e) {
        tableDispatch(setCellLoading(null))
        await showError(e.message)
        tableTriggerSearch(true)
      }
    },
    [tableDispatch, tableTriggerSearch, id, onChangeTruck, truck.status],
  )

  const renderRowClassName = useCallback(({ row }) => {
    return row.eqty > 0 || row.aqty > 0 ? 'highlight' : ''
  }, [])

  const canCheckin = truck.status === 'DOCK'

  const zones = useMemo(
    () => apiLoadZones.result?.rows || [],
    [apiLoadZones.result],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps) => {
      if (props.col.id === 'product') {
        const { value, row } = props

        return (
          <CellProductName
            {...{
              ...row.product_data,
              onClick: handleClickProductName,
              value,
            }}
          />
        )
      }

      if (props.col.id === 'zones') {
        return (
          <ZoneCell
            {...{
              product: props.row.product_data,
              zones,
              zoneQty: props.value,
              canCheckin: canCheckin && props.row.aqty > 0,
              handleClickChangePutaway,
            }}
          />
        )
      }

      return defaultCellRenderer(props)
    },
    [canCheckin, handleClickProductName, zones, handleClickChangePutaway],
  )

  const handleChangeCharge = useCallback(
    async (value: string, oldValue: string) => {
      await uiApiRequest({
        action: 'purchaseOrderTrucks_setFreightCharge',
        id,
        value,
        oldValue,
      })

      await getTruck.performRequest()

      if (onChangeTruck) {
        onChangeTruck()
      }
    },
    [getTruck, onChangeTruck, id],
  )

  const handleClickFilter = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      e.stopPropagation()

      showFiltered.toggle()
    },
    [showFiltered],
  )

  const renderHeaderLabel = useCallback(
    (props: TableHeaderRenderProps) => {
      if (props.col.id === 'eqty') {
        return (
          <>
            <a
              title="Show only items with expected qty > 0"
              className={showFiltered.value ? 'text-danger' : ''}
              href="#"
              onClick={handleClickFilter}
            >
              <i className="fa fa-filter mr-2" />
            </a>{' '}
            {props.col.label}
          </>
        )
      }

      return defaultHeaderLabelRenderer(props)
    },
    [handleClickFilter, showFiltered],
  )

  const manageColumnsPanel = usePanelControl()

  const columns = useTableColumns(
    storageKey,
    tableState,
    tableDispatch,
    columnDef,
  )

  if (!id) {
    return null
  }

  if (isLoading || apiLoadZones.isLoading) {
    return <Loading />
  }

  const canLoadTruck =
    truck.id &&
    (truck.status === 'NEW' || truck.status === 'SCHEDULED') &&
    isInternal

  const canPutaway =
    truck.id &&
    ((!isInternal &&
      (truck.status === 'OTW' || truck.status === 'SCHEDULED')) ||
      (isInternal && truck.status === 'OTW'))

  return (
    <>
      <LinkButton
        title="Manage Columns"
        onClick={manageColumnsPanel.toggle}
        style={{ position: 'absolute', right: 0, top: -26 }}
      >
        <i className="fa fa-columns" />
      </LinkButton>
      <ManageColumnsPanel
        {...{ columns }}
        isOpen={manageColumnsPanel.isOpen}
        onClose={manageColumnsPanel.close}
      />
      <PutawayModal
        purchaseOrderTruckId={truck.id}
        {...{ zones, editProductZoneQty }}
        isOpen={editProductZoneQty != null}
        onClose={handleClosePutawayModal}
      />
      <Table
        border={false}
        onChangeCell={handleChangeCell}
        onClickCell={handleClickCell}
        entityName="items"
        hasPagination={false}
        hasDragDrop={
          showFiltered.value === false && tableState.sortColumn === null
        }
        {...tableState}
        {...{
          isLoading,
          renderRowClassName,
          renderCell,
          setItem: tableSetItem,
          getItem: tableGetItem,
          columnDef,
          dispatch: tableDispatch,
          didDrop,
          beginDrag,
          renderHeaderLabel,
          rows,
        }}
      />
      <TableFooter
        {...{ readOnly }}
        error={getTruck.error}
        charge={truck ? truck.freight_charge : 0}
        onChangeCharge={handleChangeCharge}
      />
      <div className="m-3">
        {!readOnly ? (
          <>
            {truck.id &&
            (truck.status === 'COMPLETE' || truck.status === 'DOCK') ? (
              <Button
                disabled={truck ? !truck.can_checkin : true}
                className="mr-2"
                onClick={handleClickCheckin}
                color="success"
              >
                {truck.status === 'COMPLETE' ? (
                  <>
                    <i className="fa fa-check mr-2" />
                    Checked In
                  </>
                ) : (
                  'Check In All'
                )}
              </Button>
            ) : null}
            {canLoadTruck ? (
              <>
                <p>
                  "Load Truck" loads the expected qty from the selected zone
                  onto the truck.
                  <br />
                  The will decrease the zone inventory and show up under the
                  "Loaded" column.
                </p>
                <Button
                  disabled={apiAction.isLoading}
                  className="mr-2"
                  onClick={handleClickLoadTruck}
                  color="success"
                >
                  Load Truck...
                </Button>
              </>
            ) : null}
            {canPutaway ? (
              <Button
                disabled={apiAction.isLoading}
                className="mr-2"
                onClick={handleClickPutaway}
                color="success"
              >
                Ready For Putaway
              </Button>
            ) : null}
          </>
        ) : null}
        <Button
          className="mr-2"
          onClick={handleClickPrintManifest}
          color="primary"
        >
          Print Manifest
        </Button>
        <DropdownButton>
          <Menu.Trigger className="mr-2 btn btn-primary">
            Print Tags
          </Menu.Trigger>
          <DropdownMenu>
            <DropdownItem onClick={handleClickPrintTags1}>
              Use expected quantity
            </DropdownItem>
            <DropdownItem onClick={handleClickPrintTags2}>
              Use arrived quantity
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
        <Button
          className="mr-2"
          onClick={handleClickPrintOverview}
          color="primary"
        >
          Print Overview
        </Button>
        {apiAction.isLoading ? <LoadingImage /> : null}
      </div>
      <div className="m-3">
        {truck.status !== 'COMPLETE' &&
        showCheckinAllZones.value &&
        apiLoadZones.result &&
        apiLoadZones.result.rows
          ? apiLoadZones.result.rows.map((zone: Zone) => {
              return (
                <ZoneButton
                  key={zone.id}
                  onClick={handleClickCheckinZone}
                  {...{ zone }}
                />
              )
            })
          : null}
      </div>
      <div className="m-3">
        {truck.status !== 'COMPLETE' && showLoadTruckZones.value ? (
          <>
            <p>
              Select the source zone from which you are loading onto the truck.
            </p>
            {apiLoadZones.result?.rows
              ? apiLoadZones.result.rows
                  .filter(
                    (zone: Zone) =>
                      zone.location_id == truck.source_location_id,
                  )
                  .map((zone: Zone) => {
                    return (
                      <ZoneButton
                        key={zone.id}
                        onClick={handleClickLoadTruckZone}
                        {...{ zone }}
                      />
                    )
                  })
              : null}
          </>
        ) : null}
      </div>
    </>
  )
}

export default TruckItemsTable
