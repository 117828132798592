import { Menu } from '@base-ui-components/react/menu'
import styles from './index.module.css'

export const DropdownMenu = ({
  children,
  align,
}: {
  children: React.ReactNode
  align?: 'center' | 'start' | 'end' | null
}) => {
  return (
    <Menu.Portal>
      <Menu.Positioner className={styles.Positioner} align={align || 'start'}>
        <Menu.Popup className={styles.Popup}>{children}</Menu.Popup>
      </Menu.Positioner>
    </Menu.Portal>
  )
}

export default DropdownMenu
