import type { InfoIconProps } from 'components/InfoIcon/types'
import { Tooltip } from '@base-ui-components/react/tooltip'
import styles from './index.module.css'
import ArrowSvg from 'components/ArrowSvg'

export const InfoIcon = (props: InfoIconProps) => {
  const { children, className } = props

  return (
    <Tooltip.Root delay={50}>
      <Tooltip.Trigger aria-label="Bold" className={styles.Button}>
        <i
          className={`cursor-pointer info-icon fa fa-info-circle ml-1 ${
            className || ''
          }`}
        />
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Positioner sideOffset={10}>
          <Tooltip.Popup className={styles.Popup}>
            <Tooltip.Arrow className={styles.Arrow}>
              <ArrowSvg />
            </Tooltip.Arrow>
            {children}
          </Tooltip.Popup>
        </Tooltip.Positioner>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}
