import { Menu } from '@base-ui-components/react/menu'
import styles from './index.module.css'

export const DropdownToggle = ({
  children,
  hasValue,
  isFilter,
  disabled,
  onClick,
  caret,
}: {
  children: React.ReactNode
  isFilter?: boolean
  hasValue?: boolean
  disabled?: boolean
  caret?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <Menu.Trigger
      {...{ disabled, onClick }}
      className={styles.Button}
      data-has-value={hasValue ? 'hasValue' : null}
      data-is-filter={isFilter ? '1' : null}
      data-show-caret={caret !== false ? '1' : null}
    >
      {children}
    </Menu.Trigger>
  )
}

export default DropdownToggle
