import { Menu } from '@base-ui-components/react/menu'
import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import Loading from 'components/Loading'
import { useCallback } from 'react'
import { Card, CardBody, CardHeader, Progress } from 'reactstrap'
import { DropdownButton, DropdownItem, DropdownMenu } from 'components/Dropdown'
import styles from './styles.module.css'

type Props = {
  isLoading: boolean
  onClickEdit: () => void
  onClickPrintOverview: () => void
  onClickLog: () => void
  purchaseOrder: PurchaseOrder
  isDraftsView?: boolean
}

const OverviewCard = (props: Props) => {
  const {
    isLoading,
    onClickEdit,
    onClickPrintOverview,
    onClickLog,
    purchaseOrder,
    isDraftsView,
  } = props
  const handleClickEdit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onClickEdit()
    },
    [onClickEdit],
  )

  const renderAddress = useCallback(() => {
    if (!purchaseOrder || !purchaseOrder.supplier) {
      return null
    }

    const { supplier } = purchaseOrder

    return (
      <div>
        {supplier.name}
        <br />
        {supplier.phone}
        <br />
        {supplier.email}
        <br />
      </div>
    )
  }, [purchaseOrder])

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="align-bottom">Overview</span>
        <div className="card-header-actions">
          {onClickEdit ? (
            <a
              href="#"
              onClick={handleClickEdit}
              className="card-header-action btn btn-setting"
            >
              <i className="fa fa-pencil" />
            </a>
          ) : null}
          <DropdownButton>
            <Menu.Trigger className={styles.MenuTrigger}>
              <i style={{ width: 20 }} className="fa fa-ellipsis-v" />
            </Menu.Trigger>
            <DropdownMenu>
              <DropdownItem onClick={onClickPrintOverview}>Print</DropdownItem>
              <DropdownItem onClick={onClickLog}>Log</DropdownItem>
            </DropdownMenu>
          </DropdownButton>
        </div>
      </CardHeader>
      <CardBody className="d-flex flex-column">
        {!purchaseOrder && isLoading ? (
          <Loading />
        ) : purchaseOrder ? (
          <>
            <div className="mb-2" style={{ flex: 1 }}>
              {renderAddress()}
            </div>
            {purchaseOrder.supplier?.source_location ? (
              <div className="mb-2 font-italic" style={{ flex: 1 }}>
                Source Location for internal transfer: <br />
                {purchaseOrder.supplier.source_location.name}
              </div>
            ) : null}
            {!isDraftsView ? (
              <>
                <div className="mb-2" style={{ flex: 0 }}>
                  Products Received
                  <div className="float-right">
                    {purchaseOrder.percent_arrived_format}
                  </div>
                  <Progress
                    color="success"
                    value={purchaseOrder.percent_arrived}
                  />
                </div>
                <div style={{ flex: 0 }}>
                  Purchase Order Listed
                  <div className="float-right">
                    {purchaseOrder.percent_listed_format}
                  </div>
                  <Progress
                    color="primary"
                    value={purchaseOrder.percent_listed}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default OverviewCard
