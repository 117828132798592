import { apiRequest } from 'api/index'
import useInterval from 'hooks/useInterval'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

type GetDailyLeaderboardResponse = {
  date: string
  package_count: string
  nickname: string
}

const toOrdinal = (num: string) => {
  const int = Number.parseInt(num)
  const digits = [int % 10, int % 100]
  const ordinals = ['st', 'nd', 'rd', 'th']
  const oPattern = [1, 2, 3, 4]
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3]
}

const tdStyle = {
  color: '#95D3C9',
}

const headerStyle = {
  color: '#ed3538',
}

const LeaderboardCard = ({
  numBoxesShipped,
  numPlantsShipped,
  onToggleStats,
}) => {
  const [leaderboard, setLeaderboard] = useState([])
  const [ath, setAth] = useState<GetDailyLeaderboardResponse>()

  const fetchUpdate = async () => {
    try {
      const date =
        document.location.search.indexOf('?date=') === 0
          ? document.location.search.substring(6)
          : ''
      const ret = await apiRequest({
        action: 'shippingreport_getDailyLeaderboard',
        date,
      })

      if (ret) {
        if (ret.leaderboard) {
          setLeaderboard(ret.leaderboard)
        }
        if (ret.ath?.length) {
          const ath = ret.ath[0]
          ath.date = moment(ath.date).format('M/D/YY')
          setAth(ath)
        }
      }
    } catch (_e) {
      /* Ignore */
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: run once
  useEffect(() => {
    fetchUpdate()
  }, [])

  useInterval(() => {
    fetchUpdate()
  }, 30000)

  return (
    <Card
      style={{ backgroundColor: 'black', fontFamily: 'arcade', fontSize: 10 }}
      className="mb-3"
      onClick={onToggleStats}
    >
      <CardBody className="text-align-center">
        <Row>
          <Col>
            <div>
              <div style={headerStyle}>Your Stats</div>
              <table
                style={{ backgroundColor: 'black' }}
                className="table table-condenced2"
              >
                <tbody>
                  <tr>
                    <td style={tdStyle}>Boxes: {numBoxesShipped}</td>
                    <td style={tdStyle}>Plants: {numPlantsShipped}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {ath ? (
                <>
                  <div style={headerStyle}>All time high</div>
                  <table
                    style={{ backgroundColor: 'black' }}
                    className="table table-condenced2"
                  >
                    <tbody>
                      <tr>
                        <td style={tdStyle}>{ath.date}</td>
                        <td style={tdStyle}>{ath.package_count}</td>
                        <td style={tdStyle}>{ath.nickname}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
            <div>
              <div style={headerStyle}>Leaderboard</div>
              <table
                style={{ backgroundColor: 'black' }}
                className="table table-condenced2 mb-0"
              >
                <tbody>
                  {leaderboard.map((item, i) => {
                    const n = i + 1
                    const position = toOrdinal(String(n))

                    return (
                      <tr key={item.nickname}>
                        <td style={tdStyle}>{position}</td>
                        <td style={tdStyle}>{item.nickname}</td>
                        <td style={tdStyle}>
                          {Number.parseInt(item.package_count, 10)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default LeaderboardCard
