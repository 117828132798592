import { useCallback, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItemCheckbox,
} from 'components/Dropdown'

type Option = {
  label: string
  labelLong?: string
  value: string
}

type Props = {
  label: string
  filter: FilterType
  options: Option[]
  hideIfEmpty?: boolean
  showValueWithPrefix?: boolean
  clearLabel?: string
  clearIcon?: string
}

const SelectOneFilterButton = (props: Props) => {
  const { label, filter, options, hideIfEmpty, showValueWithPrefix } = props

  const clearLabel = props.clearLabel || (hideIfEmpty ? 'Remove' : 'Clear')
  const clearIcon = props.clearIcon || 'ban'

  const handleClick = useCallback(
    (id: string) => {
      filter.setValues([id])
    },
    [filter],
  )

  const handleClickClear = useCallback(() => filter.clear(), [filter])

  const labels = useMemo(() => {
    const ret = []
    for (const option of options) {
      ret[option.value] = option.label
    }
    return ret
  }, [options])

  if (hideIfEmpty && filter.values.length === 0) {
    return null
  }

  return (
    <Dropdown>
      <DropdownToggle isFilter hasValue={filter.values.length !== 0}>
        {filter.values.length === 0 ? (
          <span>{label}</span>
        ) : (
          <span>
            {showValueWithPrefix === true ? `${label}: ` : ''}
            {labels[filter.values[0]]}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickClear}>
          <i className={`mr-1 fa fa-${clearIcon}`} /> {clearLabel}
        </DropdownItem>
        {options.map((o) => {
          return (
            <DropdownItemCheckbox
              autoclose
              id={o.value}
              key={o.value}
              onClick={handleClick}
              isChecked={filter.has(o.value)}
            >
              {o.labelLong ? o.labelLong : o.label}
            </DropdownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default SelectOneFilterButton
