import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import AuthContext from 'AuthContext'
import {
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'
import { Component, Fragment } from 'react'
import { Badge, Nav, NavItem } from 'reactstrap'

type DefaultHeaderProps = {
  onLogout: (e: React.MouseEvent<HTMLElement>) => void
}

class DefaultHeader extends Component<DefaultHeaderProps> {
  static contextType = AuthContext
  declare context: React.ContextType<typeof AuthContext>

  render() {
    const login = this.context.userName || ''

    const envBadge = global.appConfig.envBadge
    const profileImageUrl =
      this.context.profileImageUrl || '../../assets/img/default_profile.jpg'

    return (
      <Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: '/assets/img/logo.png',
            width: 46,
            height: 37,
            alt: 'TTC',
          }}
          minimized={{
            src: '/assets/img/logo.png',
            width: 46 * 0.8,
            height: 37 * 0.8,
            alt: 'TTC',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <div style={{ color: 'white' }}>The Tree Center</div>
        <Nav className="ml-auto" navbar>
          {envBadge ? (
            <NavItem className="mr-2">
              <Badge style={{ fontSize: '15px' }} color="primary">
                {envBadge}
              </Badge>
            </NavItem>
          ) : null}
          <DropdownButton>
            <DropdownToggle caret={false}>
              <img
                width={35}
                height={35}
                src={profileImageUrl}
                className="img-avatar"
                alt={login}
              />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <strong>{login}</strong>
              </DropdownItem>
              <a
                target="noopener"
                href={`${global.appConfig.wpBaseUrl}/wp-admin/admin.php?page=WFLS`}
                tabIndex={0}
                role="menuitem"
                className="dropdown-item"
              >
                <i className="fa fa-gear" /> 2FA Settings...
              </a>
              <DropdownItem onClick={(_id, e) => this.props.onLogout(e)}>
                <i className="fa fa-lock" /> Logout
              </DropdownItem>
            </DropdownMenu>
          </DropdownButton>
        </Nav>
      </Fragment>
    )
  }
}

export default DefaultHeader
