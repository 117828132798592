import { intersection } from 'lodash'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItemCheckbox,
} from 'components/Dropdown'

type Props = {
  label: string
  prefixLabel?: string
  filter: FilterType
  values?: string[]
  options?: { value: string; label: string }[]
  exclusiveValues?: string[]
}

const DropDownFilterButton = (props: Props) => {
  const { label, prefixLabel, filter, exclusiveValues } = props

  const options =
    props.options || props.values?.map((value) => ({ value, label: value }))

  const handleToggle = useCallback(
    (val: string) => {
      if (!exclusiveValues) {
        filter.toggle(val)
        return
      }

      if (
        intersection(filter.values, exclusiveValues).length > 0 &&
        !exclusiveValues.includes(val)
      ) {
        const values = filter.values.filter((v) => !exclusiveValues.includes(v))
        filter.setValues([val, ...values])
        return
      }

      if (exclusiveValues.includes(val) && !filter.values.includes(val)) {
        filter.setValues([val])
        return
      }

      filter.toggle(val)
    },
    [filter, exclusiveValues],
  )

  return (
    <Dropdown>
      <DropdownToggle isFilter hasValue={filter.values.length !== 0}>
        {filter.values.length === 0 ? (
          <span>{label}</span>
        ) : filter.values.length === 1 ? (
          <span>
            {prefixLabel}
            {filter.values[0]}
          </span>
        ) : (
          <span>
            {prefixLabel}
            {filter.values.join(',')}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {options.map(({ value, label }) => {
          return (
            <DropdownItemCheckbox
              id={value}
              key={value}
              onClick={handleToggle}
              isChecked={filter.has(value)}
            >
              {label}
            </DropdownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropDownFilterButton
