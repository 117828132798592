import difference from 'lodash/difference'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItemCheckbox,
} from 'components/Dropdown'
import type { ManageBatchesFiltersType } from '.'

const batchStatusLabel = (status: string) => {
  return status.charAt(0).toUpperCase() + status.slice(1)
}

const selectedLabel = (values: string[], allValues: string[]) => {
  if (values.length === 0 || allValues.length === values.length) {
    return 'Status: All'
  }

  if (values.length === 1) {
    return values[0]
  }

  if (values.length <= 3) {
    return values.map((s) => batchStatusLabel(s)).join(',')
  }

  if (values.length >= allValues.length - 2) {
    const excludeStatus = difference(allValues, values)
    return `Not ${excludeStatus.join('/')}`
  }

  return `Status: ${values.length} selected`
}

const StatusFilterButton = (props: {
  filters: ManageBatchesFiltersType
  batchStatuses: any[]
}) => {
  const { filters, batchStatuses } = props

  const handleClick = useCallback(
    (id, e) => {
      const { metaKey, shiftKey } = e

      if (metaKey || shiftKey) {
        const values = batchStatuses.filter((s) => s !== id)
        filters.status.setValues(values)
        return
      }

      filters.status.toggle(id)
    },
    [filters.status, batchStatuses],
  )

  const handleClickClear = useCallback(
    () => filters.status.clear(),
    [filters.status],
  )

  return (
    <Dropdown>
      <DropdownToggle
        isFilter
        caret
        hasValue={filters.status.values.length !== 0}
      >
        <span>{selectedLabel(filters.status.values, batchStatuses)}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickClear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {batchStatuses.map((status) => {
          return (
            <DropdownItemCheckbox
              id={status}
              key={status}
              onClick={handleClick}
              isChecked={filters.status.has(status)}
            >
              {batchStatusLabel(status)}
            </DropdownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default StatusFilterButton
