import type { ApiTag } from '@ttc/api/tags'
import { TagLabel } from 'containers/common/TagActionButton'
import keyBy from 'lodash/keyBy'
import { useCallback, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItemCheckbox,
} from 'components/Dropdown'

export const TagColors = (props: { tags: ApiTag[] }) => {
  const { tags } = props

  return tags.map((tag) => (
    <div
      title={tag.label}
      key={tag.color}
      style={{
        width: 10,
        height: 10,
        borderRadius: 4,
        background: tag.color,
      }}
      className="d-inline-block mr-1"
    />
  ))
}

const TagFilterButton = ({
  tags,
  filter,
}: {
  tags: ApiTag[]
  filter: FilterType
}) => {
  const handleClick = useCallback(
    (id: string) => {
      filter.toggle(id)
    },
    [filter],
  )

  const handleClickClear = useCallback(() => {
    filter.clear()
  }, [filter])

  const tagsKeyed = useMemo(
    () =>
      keyBy(
        tags.filter((t) => filter.has(String(t.id))),
        'id',
      ),
    [tags, filter],
  )

  return (
    <Dropdown>
      <DropdownToggle isFilter hasValue={filter.values.length !== 0}>
        <span className="mr-1">Tag</span>
        {filter.values.length === 1 ? (
          <TagLabel {...tagsKeyed[filter.values[0]]} />
        ) : (
          <TagColors {...{ tags: Object.values(tagsKeyed) }} />
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickClear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {tags.map((tag) => {
          return (
            <DropdownItemCheckbox
              id={tag.id}
              key={tag.id}
              onClick={handleClick}
              isChecked={filter.has(tag.id)}
            >
              <TagLabel {...tag} />
            </DropdownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default TagFilterButton
