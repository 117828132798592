import { openPrintWindow } from 'api'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

const PrintActionButton = (props: { selectedRows: string[] }) => {
  const { selectedRows } = props

  const handleClickPickList = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_batches_printPullList',
      groupBy: 'category',
      print: '1',
      ids: selectedRows.join(','),
    })
  }, [selectedRows])

  const handleClickPickList2 = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_batches_printPullList',
      groupBy: 'size',
      print: '1',
      ids: selectedRows.join(','),
    })
  }, [selectedRows])

  const handleClickOrderAssembly = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_batches_printOrderAssembly',
      print: '1',
      ids: selectedRows.join(','),
    })
  }, [selectedRows])

  const disabled = selectedRows.length === 0

  return (
    <Dropdown>
      <DropdownToggle caret disabled={disabled}>
        Print
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickPickList}>
          Batch Pull List
        </DropdownItem>
        <DropdownItem onClick={handleClickPickList2}>
          Batch Check-In List
        </DropdownItem>
        <DropdownItem onClick={handleClickOrderAssembly}>
          Order Assembly
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default PrintActionButton
