import { LoadingImage } from 'components'
import { type ApiCall, useApi } from 'hooks'
import { useCallback, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  selectedRows: string[]
  onModified: () => void
  apiLoadDiscounts: ApiCall
}

const DiscountActionButton = (props: Props) => {
  const { onModified, selectedRows, apiLoadDiscounts } = props

  const discounts = useMemo(
    () => [...(apiLoadDiscounts.result ? apiLoadDiscounts.result.rows : [])],
    [apiLoadDiscounts],
  )

  const assignApi = useApi(
    () => ({ action: 'discounts_assign' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickAssign = useCallback(
    async (id: number) => {
      await assignApi.performRequest({
        json: { discountId: id, ids: selectedRows },
      })
      onModified()
    },
    [assignApi, selectedRows, onModified],
  )

  const disabled = selectedRows.length === 0
  const { isLoading } = assignApi

  return (
    <Dropdown>
      <DropdownToggle caret disabled={disabled}>
        Discount {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        {discounts.map((discount) => (
          <DropdownItem
            key={discount.id}
            onClick={() => handleClickAssign(discount.id)}
          >
            Assign "{discount.name}"
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DiscountActionButton
