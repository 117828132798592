import { useCallback } from 'react'
import styles from './index.module.css'
import { Menu } from '@base-ui-components/react/menu'

export const DropdownItem = ({
  id,
  children,
  onClick,
  disabled,
  title,
}: {
  id?: string
  children: React.ReactNode
  onClick?: (id: string | null, e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  title?: string
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onClick) {
        onClick(id, e)
      }
    },
    [id, onClick],
  )

  return (
    <Menu.Item
      {...{ title, disabled }}
      className={styles.Item}
      onClick={handleClick}
    >
      {children}
    </Menu.Item>
  )
}

export default DropdownItem
