import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  onAddSingle: () => void
  onAddBatch?: () => void
}

const AddActionButton = (props: Props) => {
  const { onAddSingle, onAddBatch } = props

  return (
    <Dropdown>
      <DropdownToggle>Add</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onAddSingle}>Add Single Gift Card</DropdownItem>
        <DropdownItem onClick={onAddBatch}>
          Add Physical Gift Cards Batch
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AddActionButton
