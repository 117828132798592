import { getApiBaseUrl } from 'api'
import type { Column } from 'app/components/Table/reducer/types'
import type { Columns } from 'components/Table/types'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'
import Swal from 'sweetalert2'

const DEFAULT_LIMIT = 3000

const ExportActionButton = ({
  query,
  requestProps,
  sortColumn,
  sortAsc,
  columns,
}: {
  query: string
  requestProps: Record<string, string>
  sortColumn: string
  sortAsc: boolean
  columns: Columns
}) => {
  const handleClickExport = useCallback(
    async (format: string, e) => {
      const performExport = (limit: string) => {
        const visibleColumns = columns.visibleSorted.filter(
          (column: Column) => column.canExport !== false,
        )

        const params = {
          ...requestProps,
          query,
          sortColumn,
          sortAsc,
          columns: visibleColumns,
          limit,
        }

        const paramsEncoded = encodeURIComponent(JSON.stringify(params))

        let url = `${getApiBaseUrl()}/?action=treeadmin_orders_export`
        url += `&params=${paramsEncoded}`
        url += `&format=${format}`

        if (e.shiftKey) {
          url = `${url}&text=1`
        }

        window.open(url)
      }

      await Swal.fire({
        title: `Export ${format.toUpperCase()}`,
        text: 'This will export all visible Orders up until the following limit: (max. 10000).',
        input: 'text',
        inputValue: DEFAULT_LIMIT,
        inputPlaceholder: String(DEFAULT_LIMIT),
        showCancelButton: true,
        confirmButtonText: 'Export',
        showLoaderOnConfirm: true,
        preConfirm: async (limit) => {
          performExport(limit)
        },
      })
    },
    [requestProps, query, sortColumn, sortAsc, columns],
  )

  return (
    <Dropdown>
      <DropdownToggle caret>Export</DropdownToggle>
      <DropdownMenu>
        <DropdownItem id="csv" onClick={handleClickExport}>
          Export CSV
        </DropdownItem>
        <DropdownItem id="xlsx" onClick={handleClickExport}>
          Export Excel
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ExportActionButton
