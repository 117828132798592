import { useApi, useStateful } from 'hooks'
import moment from 'moment'
import { useCallback } from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import { askQuestion } from 'utils'
import { Popover, PopoverPopup, PopoverToggle } from 'components/Popover'

type Props = {
  selectedRows: string[]
  onOrderModified: () => void
}

const ScheduleActionButton = (props: Props) => {
  const { selectedRows, onOrderModified } = props

  const scheduledFor = useApi({ action: 'orders_scheduleFor' }, null, {
    errorModal: true,
  })

  const date = useStateful('')

  const handleChangeDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      date.set(e.currentTarget.value)
    },
    [date],
  )

  const handleClickApply = useCallback(async () => {
    if (
      !(await askQuestion(
        'Are you sure you want to schedule the selected orders for the selected date?',
      ))
    ) {
      return
    }

    await scheduledFor.performRequest({
      json: { ids: selectedRows, date: date.value },
    })

    if (onOrderModified) {
      onOrderModified()
    }
  }, [onOrderModified, selectedRows, scheduledFor, date.value])

  const disabled = selectedRows.length === 0

  const min = moment().add(1, 'day').format('YYYY-MM-DD')

  return (
    <Popover>
      <PopoverToggle caret disabled={disabled}>
        Schedule
      </PopoverToggle>
      <PopoverPopup>
        <Form className="m-3" action="" method="post">
          <FormGroup>
            <Label htmlFor="input-date-from" className="pr-1">
              Schedule for:
            </Label>
            <Input
              name="from"
              onChange={handleChangeDate}
              value={date.value}
              type="date"
              id="input-hold-date"
              placeholder=""
              required
              min={min}
            />
          </FormGroup>
          <Button
            color="primary"
            disabled={date.value === ''}
            onClick={handleClickApply}
            size="sm"
          >
            Apply
          </Button>
        </Form>
      </PopoverPopup>
    </Popover>
  )
}

export default ScheduleActionButton
