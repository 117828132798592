import { useCallback } from 'react'
import styles from 'components/Dropdown/index.module.css'

type Props = {
  id?: string
  children?: React.ReactNode
  onClick: (id: string, e: Event) => void
  isChecked: boolean
  isIntermediate?: boolean
}

export const PopoverItemCheckbox = (props: Props) => {
  const { children, onClick, isChecked, isIntermediate, id } = props

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(id, e)
      }
    },
    [id, onClick],
  )

  return (
    <div data-id={id} onClick={handleClick} className={styles.CheckboxItem}>
      {isChecked ? (
        <i style={{ color: '#4394ee' }} className="fa fa-check-square" />
      ) : isIntermediate ? (
        <i style={{ color: '#4394ee' }} className="fa fa-minus-square" />
      ) : (
        <i style={{ color: 'gray' }} className="fa fa-square" />
      )}
      <span className={styles.CheckboxItemText}>{children}</span>
    </div>
  )
}

export default PopoverItemCheckbox
