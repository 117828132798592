import { LoadingImage } from 'components'
import { useApi } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItemCheckbox,
} from 'components/Dropdown'

export const TagLabel = (props: { color: string; label: string }) => {
  const { color, label } = props

  return (
    <>
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: 4,
          background: color,
        }}
        className="d-inline-block mr-2"
      />
      {label}
    </>
  )
}

type Tag = {
  id: string
  label: string
  color: string
}

type TagActionButtonProps = {
  tags: Tag[]
  onClickManage: () => void
  selectedRows: string[]
  rows: any[]
  onModified: () => void
  toggleTagApiAction: string
  toggleTagApiContext?: string
  disabled?: boolean
  columnName?: string
}

const TagActionButton = (props: TagActionButtonProps) => {
  const {
    tags,
    onClickManage,
    selectedRows,
    rows,
    onModified,
    toggleTagApiAction,
    toggleTagApiContext,
  } = props

  const columnName = props.columnName || 'tags'

  const [tagsState, setTagsState] = useState({})
  useEffect(() => {
    const s = {}
    const tagRows = rows.filter((row) => selectedRows.includes(row.id))

    for (const row of tagRows) {
      if (Array.isArray(row[columnName])) {
        for (const tag of row[columnName]) {
          if (!Object.hasOwn(s, tag.tag_id)) {
            s[tag.tag_id] = 0
          }

          s[tag.tag_id] += 1
        }
      }
    }

    for (const id of Object.keys(s)) {
      s[id] = s[id] === selectedRows.length ? 1 : s[id] === 0 ? 0 : 2
    }

    setTagsState(s)
  }, [rows, selectedRows, columnName])

  const toggleTagsApi = useApi(
    () => ({ action: toggleTagApiAction }),
    null,
    () => ({ errorModal: true }),
  )

  const toggleTag = useCallback(
    async (id: string) => {
      const toggleState = tagsState[id] || 0

      await toggleTagsApi.performRequest({
        json: {
          tag_id: id,
          ids: selectedRows,
          state: toggleState === 0,
          context: toggleTagApiContext,
        },
      })

      if (onModified) {
        onModified()
      }
    },
    [selectedRows, tagsState, toggleTagsApi, onModified, toggleTagApiContext],
  )

  const handleClick = useCallback(
    (id: string) => {
      toggleTag(id)
    },
    [toggleTag],
  )

  const disabled = props.disabled || selectedRows.length === 0
  const isLoading = toggleTagsApi.isLoading

  return (
    <Dropdown>
      <DropdownToggle {...{ disabled }}>
        Tag {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        {tags.map((tag) => {
          const tagState = tagsState[tag.id] || 0

          return (
            <DropdownItemCheckbox
              key={tag.id}
              id={tag.id}
              onClick={handleClick}
              isChecked={tagState === 1}
              isIntermediate={tagState === 2}
            >
              <TagLabel {...tag} />
            </DropdownItemCheckbox>
          )
        })}
        <DropdownItem onClick={onClickManage}>Manage...</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default TagActionButton
