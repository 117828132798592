import type { Product } from '@ttc/api/products'
import { Link } from 'react-router'

type Props = {
  sku: string
  availableQty?: number
  name?: string
  text?: string
  popOverProductData?: Product
}

export const InventoryLink = ({ sku, name, availableQty, text }: Props) => {
  return text ? (
    <Link className="m-0 p-0 pl-2" to={`/inventory/?q=${sku}`}>
      {text}
    </Link>
  ) : (
    <div>
      Inventory: <Link to={`/inventory/?q=${sku}`}>{name ?? sku}</Link>
      {availableQty ? ` (${availableQty})` : null}
    </div>
  )
}

export default InventoryLink
