import type { GiftCardBatch } from '@ttc/api/giftcards'
import { DropDownFilterButton, SelectOneFilterButton } from 'components'
import NumericFilterButton from 'components/Filters/NumericFilterButton'
import {
  type FiltersProps,
  TypeEnum,
} from 'containers/ManageGiftCards/Filters/types'
import BoolFilterButton from 'containers/common/Filters/BoolFilterButton'
import { useMemo } from 'react'

const Filters = ({ filters, apiGetBatches }: FiltersProps) => {
  const batchOptions = useMemo(() => {
    return (
      apiGetBatches.result?.map((batch: GiftCardBatch) => ({
        value: batch.id,
        label: `Batch ${batch.id}`,
        labelLong: (
          <>
            <b>Batch {batch.id}</b> - {batch.count} × ${Number(batch.amount)}{' '}
            <br />
            <small>Created at {batch.created_at}</small>
            <br />
            <small>
              Active: {Number(batch.percent_active)}%, Balance:
              {Number(batch.balance_percentage)}%
            </small>
          </>
        ),
      })) ?? []
    )
  }, [apiGetBatches.result])

  return (
    <>
      <SelectOneFilterButton
        label="Type"
        filter={filters.type}
        options={[
          {
            value: TypeEnum.STORE_CREDIT,
            label: 'Store Credit',
          },
          {
            value: TypeEnum.GIFTCARD,
            label: 'Giftcard',
          },
        ]}
      />
      <NumericFilterButton label="Amount" filter={filters.amount} />
      <NumericFilterButton label="Balance" filter={filters.balance} />
      <BoolFilterButton
        label="Has Order"
        filters={filters}
        boolFilters={[
          {
            name: 'hasOrder',
            label: 'Has Order',
            optionLabels: ['No', 'Yes'],
          },
        ]}
      />
      <DropDownFilterButton
        label="Status"
        values={['Active', 'Expired', 'Deactivated', 'Consumed']}
        filter={filters.status}
      />
      <SelectOneFilterButton
        label="Batch"
        options={batchOptions}
        filter={filters.batchId}
      />
    </>
  )
}

export default Filters
