import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  onAddVariation: () => void
  onAddSimple: () => void
}

const AddActionButton = (props: Props) => {
  const { onAddVariation, onAddSimple } = props

  return (
    <Dropdown>
      <DropdownToggle caret>Add</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onAddVariation}>
          Variable Product with Variation
        </DropdownItem>
        <DropdownItem onClick={onAddSimple}>
          Simple Product (Addon)
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AddActionButton
