import { LoadingImage } from 'components/Loading'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  selectedRows: string[]
  onModified: () => void
}

const StatusButton = (props: Props) => {
  const { onModified, selectedRows } = props

  const setProductStatus = useApi(
    () => ({ action: 'dropshipProducts_setStatus' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleActivate = useCallback(async () => {
    await setProductStatus.performRequest({
      json: { ids: selectedRows, isEnabled: true },
    })
    onModified()
  }, [onModified, selectedRows, setProductStatus])

  const handleDeactivate = useCallback(async () => {
    await setProductStatus.performRequest({
      json: { ids: selectedRows, isEnabled: false },
    })
    onModified()
  }, [onModified, selectedRows, setProductStatus])

  const { isLoading } = setProductStatus

  return (
    <Dropdown>
      <DropdownToggle caret>
        Status {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          disabled={selectedRows.length === 0}
          onClick={handleActivate}
        >
          Activate
        </DropdownItem>
        <DropdownItem
          disabled={selectedRows.length === 0}
          onClick={handleDeactivate}
        >
          Deactivate
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default StatusButton
