import { Tooltip } from '@base-ui-components/react/tooltip'
import { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.css'
import ArrowSvg from 'components/ArrowSvg'

type Props = {
  value: string
  text?: any
  style?: any
  className?: string
}

export const ClipboardButton = ({ className, text, value, style }: Props) => {
  const [isCopied, setCopied] = useState(false)
  const timeout = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()

      setCopied(true)

      try {
        navigator.clipboard.writeText(value)
      } catch (_e) {
        /* Ignore */
      }

      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        setCopied(false)
      }, 600)
    },
    [value],
  )

  return (
    <>
      {text ? <span className="mr-1">{text}</span> : null}

      {!isCopied ? (
        <Tooltip.Root key="tooltip" delay={250}>
          <Tooltip.Trigger className={styles.Button} onClick={handleClick}>
            <i
              className={`clipboard-copy-icon fa fa-copy ${className || ''}`}
              style={style}
            />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Positioner sideOffset={10} side="right">
              <Tooltip.Popup className={styles.Popup}>
                <Tooltip.Arrow className={styles.Arrow}>
                  <ArrowSvg data-side="right" />
                </Tooltip.Arrow>
                Click to copy
              </Tooltip.Popup>
            </Tooltip.Positioner>
          </Tooltip.Portal>
        </Tooltip.Root>
      ) : (
        <Tooltip.Root
          delay={100}
          key={isCopied ? 'tooltip-open' : 'tooltip'}
          open={isCopied}
        >
          <Tooltip.Trigger className={styles.Button}>
            <i
              className={`clipboard-copy-icon fa fa-copy ${className || ''}`}
              style={style}
            />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Positioner sideOffset={10} side="right">
              <Tooltip.Popup className={styles.Popup}>
                <Tooltip.Arrow className={styles.Arrow}>
                  <ArrowSvg data-side="right" />
                </Tooltip.Arrow>
                {isCopied ? 'Copied!' : 'Click to copy'}
              </Tooltip.Popup>
            </Tooltip.Positioner>
          </Tooltip.Portal>
        </Tooltip.Root>
      )}
    </>
  )
}

export default ClipboardButton
