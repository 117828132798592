import { Popover } from '@base-ui-components/react/popover'
import styles from 'components/Dropdown/index.module.css'

export const PopoverPopup = ({
  children,
  align,
}: {
  children: React.ReactNode
  align?: 'center' | 'start' | 'end' | null
}) => {
  return (
    <Popover.Portal>
      <Popover.Positioner
        className={styles.Positioner}
        align={align || 'start'}
      >
        <Popover.Popup className={styles.Popup}>{children}</Popover.Popup>
      </Popover.Positioner>
    </Popover.Portal>
  )
}

export default PopoverPopup
