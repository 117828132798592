import { SelectOneFilterButton } from 'components'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type BoolFilterProps = {
  name: string
  label: string
  // list of labels for the different options
  optionLabels: string[]
}
type BoolFilterButtonProps = {
  // `label`: The label to be displayed on the dropdown toggle button.
  label: string
  // `filters`: An object for managing the filters. It should have `addFilter` and `has` methods.
  filters: FiltersTypeWithUrlUpdater
  // `boolFilters`: An array of boolean filter objects.
  boolFilters: BoolFilterProps[]
}

/**
 * `BoolFilterButton` is a dropdown button component for selecting boolean filters.
 *
 * The component takes a `BoolFilterButtonProps` object as its argument, which should include:
 *
 * When a filter is selected from the dropdown, it is added to the `filters` object and a `SelectOneFilterButton` is rendered for that filter.
 *
 * Example usage:
 *
 * ```
 * <BoolFilterButton
 *   label="Filter"
 *   filters={myFilters}
 *   boolFilters={[
 *     { name: 'isTrue', label: 'Is True', optionLabels: ['No', 'Yes'] },
 *     { name: 'isFalse', label: 'Is False', optionLabels: ['Yes', 'No'] },
 *   ]}
 * />
 * ```
 */
const BoolFilterButton = (props: BoolFilterButtonProps) => {
  const { label, filters, boolFilters } = props

  const handleClickAddBoolFilter = useCallback(
    (id: string) => {
      filters.addFilter(id, ['0'])
    },
    [filters],
  )

  return (
    <>
      <Dropdown>
        <DropdownToggle isFilter>
          <span>{label}</span>
        </DropdownToggle>
        <DropdownMenu>
          {boolFilters.map((boolFilter) => {
            return (
              <DropdownItem
                id={boolFilter.name}
                key={boolFilter.name}
                data-filter={boolFilter.name}
                onClick={handleClickAddBoolFilter}
              >
                <i className="mr-1" /> {boolFilter.label}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
      {boolFilters.map((boolFilter) => {
        if (!filters.has(boolFilter.name)) {
          return
        }

        return (
          <SelectOneFilterButton
            key={boolFilter.name}
            {...{
              hideIfEmpty: true,
              filter: filters[boolFilter.name],
              label: boolFilter.label,
              options: [
                { value: '0', label: boolFilter.optionLabels[0] },
                { value: '1', label: boolFilter.optionLabels[1] },
              ],
            }}
          />
        )
      })}
    </>
  )
}

export default BoolFilterButton
