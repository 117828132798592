import type { GetOrderApiResult } from '@ttc/api/orders'
import {
  ClipboardButton,
  EditPanel,
  Loading,
  TabBar,
  useTabBar,
} from 'components'
import type { TabsConfig } from 'components/TabBar/types'
import type { ApiCall } from 'hooks'
import type { PanelControlResult } from 'hooks/usePanelControl'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import CustomerCard, { ShippingCard } from './CustomerCard'
import GiftCardCard from './GiftCardCard'
import InfoCard from './InfoCard'
import InvoiceCard from './InvoiceCard'
import LogCard from './LogCard'
import MailsCard from './MailsCard'
import NotesCard from './NotesCard'
import OrderSplitCards from './OrderSplitCards'
import OrderSplitDeliveryCards from './OrderSplitDeliveryCards'
import PaymentCard from './PaymentCard'
import StoreCreditsCard from './StoreCreditsCard'

const TAB_ORDER = 'order'
const TAB_PAYMENT = 'payment'
const TAB_DELIVERY = 'delivery'
const TAB_NOTES = 'notes'

type Tab =
  | typeof TAB_ORDER
  | typeof TAB_PAYMENT
  | typeof TAB_DELIVERY
  | typeof TAB_NOTES

const tabsConfig: TabsConfig<Tab> = [
  { type: TAB_ORDER, title: 'Order', icon: 'leaf' },
  { type: TAB_PAYMENT, title: 'Payment', icon: 'money' },
  { type: TAB_DELIVERY, title: 'Delivery', icon: 'truck' },
  { type: TAB_NOTES, title: 'Notes', icon: 'notes' },
]

type OrderEditorProps = {
  issueStoreCreditPanel: PanelControlResult
  canEditOrder: boolean
  canModifyOrderAddress: boolean
  canIssueNewStoreCredit: boolean
  onToggle: (e: React.MouseEvent<HTMLElement>) => void
  onBack: () => void
  getOrder: ApiCall<GetOrderApiResult>
  renderFirstOrderCard: (a: ApiCall<GetOrderApiResult>) => React.JSX.Element
  onClickCancel: (splitOrderNumber: string) => void
  onClickReturnId: (id: string) => void
  onClickCreateReturn: () => void
  showDropshipAvailability: boolean
}

const OrderEditor = (props: OrderEditorProps) => {
  const navigate = useNavigate()

  const {
    canEditOrder,
    canModifyOrderAddress,
    canIssueNewStoreCredit,
    onToggle,
    onBack,
    getOrder,
    renderFirstOrderCard,
    onClickCancel,
    onClickReturnId,
    onClickCreateReturn,
    issueStoreCreditPanel,
    showDropshipAvailability,
  } = props

  const tabBarState = useTabBar<Tab>(
    TAB_ORDER,
    tabsConfig,
    'orders.sidebar.selectedTab',
  )

  const orderNumber = getOrder.hasResult ? getOrder.result.orderNumber : null

  const hasGiftCards = getOrder.result?.giftCardInfo?.num_gift_cards > 0

  const href = getOrder.hasResult
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${getOrder.result.orderId}`
    : ''
  const caption = getOrder.hasResult ? (
    <>
      Order {orderNumber}{' '}
      <ClipboardButton className="ml-1" value={orderNumber} />
    </>
  ) : null

  const onClickEditOrder = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const link = `/orders/edit/${orderNumber}`

      if (e.metaKey || e.ctrlKey) {
        window.open(link, '_blank')
        return
      }

      navigate(link)
    },
    [orderNumber, navigate],
  )

  const showEditOrderButton =
    canEditOrder && window.location.pathname.indexOf('/orders/edit') !== 0

  return (
    <>
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item m-0">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list" />
          </a>
        </li>
      </ul>

      {!getOrder.hasResult && getOrder.isLoading ? (
        <div className="tab-content">
          <Loading />
        </div>
      ) : (
        <>
          <EditPanel hasForm={false} noHeader embedded isOpen {...{ caption }}>
            <Row>
              <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
                <span style={{ fontSize: '1rem' }}>
                  {onBack ? (
                    <Button className="mr-2" onClick={onBack}>
                      <i className="fa fa-chevron-left" />
                    </Button>
                  ) : null}
                  <a
                    style={{ verticalAlign: 'middle' }}
                    target="noopener"
                    href={href}
                  >
                    {caption}
                  </a>
                </span>
                {showEditOrderButton && getOrder.hasResult ? (
                  <Button
                    color="primary"
                    size="sm"
                    className="ml-2"
                    onClick={onClickEditOrder}
                  >
                    Edit Order
                  </Button>
                ) : null}
              </Col>
            </Row>
            {getOrder.error ? (
              <div className="tab-content">
                <Row>
                  <Col>
                    <div className="text-danger">{getOrder.error}</div>
                  </Col>
                </Row>
              </div>
            ) : getOrder.hasResult ? (
              <TabBar className="border-top border-bottom" {...tabBarState} />
            ) : null}
          </EditPanel>
          <div
            className="tab-content"
            style={{ height: 'calc(100vh - 210px)' }}
          >
            <EditPanel noHeader embedded isOpen hasForm={false}>
              {!getOrder.error && getOrder.hasResult ? (
                <>
                  {renderFirstOrderCard ? renderFirstOrderCard(getOrder) : null}
                  <InfoCard
                    {...{ getOrder, onClickReturnId, onClickCreateReturn }}
                  />
                  {tabBarState.selectedTab === TAB_ORDER ? (
                    <>
                      <OrderSplitCards
                        {...{
                          getOrder,
                          onClickCancel,
                          showDropshipAvailability,
                        }}
                      />
                      <CustomerCard {...{ getOrder, canModifyOrderAddress }} />
                    </>
                  ) : null}
                  {tabBarState.selectedTab === TAB_PAYMENT ? (
                    <>
                      <PaymentCard
                        {...{ readOnly: !canModifyOrderAddress, getOrder }}
                      />
                      <InvoiceCard {...{ canEditOrder, getOrder }} />
                      {hasGiftCards ? <GiftCardCard {...{ getOrder }} /> : null}
                      <StoreCreditsCard
                        {...{
                          canEditOrder,
                          getOrder,
                          panel: issueStoreCreditPanel,
                          readOnly: !canIssueNewStoreCredit,
                        }}
                      />
                    </>
                  ) : null}
                  {tabBarState.selectedTab === TAB_DELIVERY ? (
                    <>
                      <ShippingCard
                        {...{ readOnly: !canModifyOrderAddress, getOrder }}
                      />
                      <OrderSplitDeliveryCards {...{ getOrder }} />
                    </>
                  ) : null}
                  {tabBarState.selectedTab === TAB_NOTES ? (
                    <>
                      <NotesCard {...{ getOrder, readOnly: !canEditOrder }} />
                      <MailsCard canResend={canEditOrder} {...{ getOrder }} />
                      <LogCard {...{ getOrder, readOnly: !canEditOrder }} />
                    </>
                  ) : null}
                </>
              ) : null}
            </EditPanel>
          </div>
        </>
      )}
    </>
  )
}

export default OrderEditor
