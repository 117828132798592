import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  onExport: () => void
}

const ExportActionButton = (props: Props) => {
  const { onExport } = props

  return (
    <Dropdown>
      <DropdownToggle caret>Export</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onExport}>Export Batch CSV</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ExportActionButton
