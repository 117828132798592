import { useCallback } from 'react'
import styles from 'components/Dropdown/index.module.css'

export const PopoverItem = ({
  id,
  children,
  onClick,
  disabled,
  title,
}: {
  id?: string
  children: React.ReactNode
  onClick?: (id: string | null, e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  title?: string
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onClick) {
        onClick(id, e)
      }
    },
    [id, onClick],
  )

  return (
    <div {...{ title, disabled }} className={styles.Item} onClick={handleClick}>
      {children}
    </div>
  )
}

export default PopoverItem
