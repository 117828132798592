import { LoadingImage } from 'components/Loading'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  selectedRows: string[]
  onModified: () => void
}

const SyncActionButton = (props: Props) => {
  const { onModified, selectedRows } = props

  const fullSyncApi = useApi(
    () => ({ action: 'dropshipOrders_fullSync' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickFullSync = useCallback(async () => {
    await fullSyncApi.performRequest()
    onModified()
  }, [onModified, fullSyncApi])

  const syncApi = useApi(
    () => ({ action: 'dropshipOrders_sync' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickSync = useCallback(async () => {
    await syncApi.performRequest({
      json: { ids: selectedRows },
    })
    onModified()
  }, [onModified, syncApi, selectedRows])

  const isLoading = fullSyncApi.isLoading || syncApi.isLoading

  return (
    <Dropdown>
      <DropdownToggle caret>
        Sync {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem title="Sync all orders" onClick={handleClickFullSync}>
          Full Sync
        </DropdownItem>
        <DropdownItem
          disabled={selectedRows.length === 0}
          title="Sync selected orders"
          onClick={handleClickSync}
        >
          Sync Selected
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SyncActionButton
