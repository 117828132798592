import { LoadingImage } from 'components/Loading'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type Props = {
  selectedRows: string[]
  onModified: () => void
}

const ActionButton = (props: Props) => {
  const { onModified, selectedRows } = props

  const unassignApi = useApi(
    () => ({ action: 'dropshipProducts_unsetProducts' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickUnassign = useCallback(async () => {
    await unassignApi.performRequest({
      json: { ids: selectedRows },
    })
    onModified()
  }, [onModified, selectedRows, unassignApi])

  const fullSyncApi = useApi(
    () => ({ action: 'dropshipProducts_fullSync' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickFullSync = useCallback(async () => {
    await fullSyncApi.performRequest()
    onModified()
  }, [onModified, fullSyncApi])

  const isLoading = unassignApi.isLoading || fullSyncApi.isLoading

  return (
    <Dropdown>
      <DropdownToggle caret>
        Action {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickFullSync}>Full Sync</DropdownItem>
        <DropdownItem
          disabled={selectedRows.length === 0}
          onClick={handleClickUnassign}
          title="Unassign TTC/DS product mapping"
        >
          Unassign
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ActionButton
