import type { Product } from '@ttc/api/products'
import type { ZonesGetAllResponse } from '@ttc/api/zones'
import AuthContext from 'AuthContext'
import { MANAGEINVENTORY_READ } from 'caps'
import useApi from 'hooks/useApi'
import { useContext } from 'react'
import { Tooltip } from '@base-ui-components/react/tooltip'
import styles from './index.module.css'
import ArrowSvg from './ArrowSvg'
import InventoryLink from './InventoryLink'

type Props = {
  sku: string
  availableQty?: number
  name?: string
  text?: string
  popOverProductData?: Product
}

export const LinkToInventory = (props: Props) => {
  const { popOverProductData } = props

  const { hasCap } = useContext(AuthContext)
  const canOpenInventory = hasCap(MANAGEINVENTORY_READ)

  const apiLoadZones = useApi<ZonesGetAllResponse>(
    () => ({ action: 'zones_getAll' }),
    null,
    { autoPerform: true, cache: true, freshCache: true },
  )

  const zones = apiLoadZones.result?.rows || []

  if (!canOpenInventory) {
    return
  }

  if (!popOverProductData.zoneQty) {
    return <InventoryLink {...props} />
  }

  return (
    <Tooltip.Root delay={100}>
      <Tooltip.Trigger className={styles.Button}>
        <InventoryLink {...props} />
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Positioner sideOffset={10} side="bottom">
          <Tooltip.Popup className={styles.Popup}>
            <Tooltip.Arrow className={styles.Arrow}>
              <ArrowSvg data-side="bottom" />
            </Tooltip.Arrow>
            <div className="popover-inner">
              <h3 className="popover-header">{popOverProductData.name}</h3>
              <div
                className="popover-body"
                style={{
                  maxHeight: 300,
                  overflow: 'hidden',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  <li>Available: {popOverProductData.availableQty}</li>
                  {zones
                    .filter((zone) => {
                      const isVariation =
                        popOverProductData.type === 'variation'
                      const isPresentInZone =
                        popOverProductData.zoneQty[zone.id] > 0
                      if (isVariation) {
                        return (
                          (!zone.is_addon_zone && !zone.is_box_zone) ||
                          isPresentInZone
                        )
                      }
                      return isPresentInZone
                    })
                    .map((zone) => (
                      <li key={zone.id}>
                        {zone.description ?? `Zone ${zone.id}`}:{' '}
                        {popOverProductData.zoneQty[zone.id]}
                      </li>
                    ))}
                  <li>Web: {popOverProductData.numWeb}</li>
                </ul>
              </div>
            </div>
          </Tooltip.Popup>
        </Tooltip.Positioner>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}
