import { openPrintWindow } from 'api'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

type PrintActionButtonProps = {
  selectedRows: string[]
}

const PrintActionButton = (props: PrintActionButtonProps) => {
  const { selectedRows } = props

  const handleClickPackingList = useCallback(async () => {
    openPrintWindow({
      action: 'treeadmin_orderSplits_printPackingSlips',
      print: '1',
      zpl: '0',
      // XXX for now, assume each order has only one order split
      order_ids: selectedRows.join(','),
      order_split_ids: undefined,
    })
  }, [selectedRows])

  const disabled = selectedRows.length === 0

  return (
    <Dropdown>
      <DropdownToggle caret disabled={disabled}>
        Print
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickPackingList}>
          Packing Slips
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default PrintActionButton
