import { Menu } from '@base-ui-components/react/menu'

export const Dropdown = ({ children }: { children: React.ReactNode }) => {
  return (
    <li className="dropdown nav-item">
      <Menu.Root modal={false}>{children}</Menu.Root>
    </li>
  )
}

export default Dropdown
