import { Popover as Popover_ } from '@base-ui-components/react/popover'

export const Popover = ({ children }: { children: React.ReactNode }) => {
  return (
    <li className="dropdown nav-item">
      <Popover_.Root modal={false}>{children}</Popover_.Root>
    </li>
  )
}

export default Popover
