import type { GetOrderApiParams, GetOrderApiResult } from '@ttc/api/orders'
import { NotesList } from 'components'
import { useApi } from 'hooks'
import React, { useCallback, useEffect } from 'react'
import ArrowSvg from './ArrowSvg'
import { Tooltip } from '@base-ui-components/react/tooltip'
import styles from './index.module.css'

type NotesIconProps = {
  id: string
  orderId: string
  header: string
  children: React.JSX.Element
  hasNotes: boolean
  value?: any
  readOnly: boolean
  isLoading: boolean
  reloadNotes: () => void
}

const Loader = ({ reloadNotes }: { id: string; reloadNotes: () => void }) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: one shot
  useEffect(() => {
    reloadNotes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

const NotesIcon = (props: NotesIconProps) => {
  const { id, header, children, hasNotes, reloadNotes } = props

  if (!hasNotes) {
    return (
      <i className={`notes-icon fa fa-comment${hasNotes ? '' : '-o'} ml-1`} />
    )
  }

  return (
    <Tooltip.Root delay={100}>
      <Tooltip.Trigger className={styles.Button}>
        <i className={`notes-icon fa fa-comment${hasNotes ? '' : '-o'} ml-1`} />
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Positioner sideOffset={10} side="right">
          <Tooltip.Popup className={styles.Popup}>
            <Tooltip.Arrow className={styles.Arrow}>
              <ArrowSvg data-side="right" />
            </Tooltip.Arrow>
            <div className="popover-inner">
              <h3 className="popover-header">{header}</h3>
              <div
                className="popover-body"
                style={{
                  maxHeight: 300,
                  overflow: 'hidden',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Loader key={id} {...{ reloadNotes, id }} />
                {children}
              </div>
            </div>
          </Tooltip.Popup>
        </Tooltip.Positioner>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}

type NotesCellProps = {
  id: string
  hasNotesFromBuyer: boolean
  hasNotesToBuyer: boolean
  hasNotesInternal: boolean
  readOnly: boolean
}

const NotesCell = React.memo((props: NotesCellProps) => {
  const { id, hasNotesFromBuyer, hasNotesToBuyer, hasNotesInternal, readOnly } =
    props

  const getOrder = useApi<GetOrderApiResult, GetOrderApiParams>(
    {
      action: 'editOrder_getOrder',
      order_id: null,
    },
    {
      notesFromBuyer: '',
      notesToBuyer: [],
      notesInternal: [],
    },
  )
  const isLoading = getOrder.isLoading
  const { notesFromBuyer, notesToBuyer, notesInternal } = getOrder.result

  const reloadNotes = useCallback(() => {
    if (getOrder.params.order_id !== id) {
      getOrder.reset()
    }

    getOrder.performRequest({ order_id: id })
  }, [getOrder, id])

  return (
    <div className="order-notes-cell">
      <NotesIcon
        id={`notes-${id}-popover1`}
        header="Notes from the buyer"
        hasNotes={hasNotesFromBuyer}
        value={notesFromBuyer}
        {...{
          isLoading,
          readOnly,
          orderId: id,
          reloadNotes,
        }}
      >
        {isLoading ? null : notesFromBuyer === '' ? (
          <i>(empty)</i>
        ) : (
          <>{notesFromBuyer}</>
        )}
      </NotesIcon>
      <NotesIcon
        id={`notes-${id}-popover2`}
        header="Notes to the buyer"
        hasNotes={hasNotesToBuyer}
        {...{
          isLoading,
          readOnly,
          orderId: id,
          reloadNotes,
        }}
      >
        {isLoading ? null : <NotesList notes={notesToBuyer} />}
      </NotesIcon>
      <NotesIcon
        id={`notes-${id}-popover3`}
        header="Internal notes"
        hasNotes={hasNotesInternal}
        {...{
          isLoading,
          readOnly,
          orderId: id,
          reloadNotes,
        }}
      >
        {isLoading ? null : <NotesList notes={notesInternal} />}
      </NotesIcon>
    </div>
  )
})

export default NotesCell
