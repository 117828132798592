import { useCallback } from 'react'
import styles from './index.module.css'
import { Menu } from '@base-ui-components/react/menu'

type Props = {
  id?: string
  children?: React.ReactNode
  autoclose?: boolean
  onClick: (id: string, e: Event) => void
  isChecked: boolean
  isIntermediate?: boolean
}

export const DropdownItemCheckbox = (props: Props) => {
  const { autoclose, children, onClick, isChecked, isIntermediate, id } = props

  const handleClick = useCallback(
    (_checked, e) => {
      if (onClick) {
        onClick(id, e)
      }
    },
    [id, onClick],
  )

  return (
    <Menu.CheckboxItem
      closeOnClick={autoclose == null ? false : !!autoclose}
      data-id={id}
      onCheckedChange={handleClick}
      className={styles.CheckboxItem}
    >
      {isChecked ? (
        <i style={{ color: '#4394ee' }} className="fa fa-check-square" />
      ) : isIntermediate ? (
        <i style={{ color: '#4394ee' }} className="fa fa-minus-square" />
      ) : (
        <i style={{ color: 'gray' }} className="fa fa-square" />
      )}
      <span className={styles.CheckboxItemText}>{children}</span>
    </Menu.CheckboxItem>
  )
}

export default DropdownItemCheckbox
