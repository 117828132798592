import { LoadingImage } from 'components'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'
import { askQuestion } from 'utils'

type Props = {
  selectedRows: string[]
  onOrderModified: () => void
}

const RestoreActionButton = (props: Props) => {
  const { selectedRows, onOrderModified } = props

  const restore = useApi({ action: 'orders_restore' }, null, {
    errorModal: true,
  })

  const handleClickRestore = useCallback(async () => {
    if (
      !(await askQuestion(
        'Are you sure you want to restore the selected orders?',
      ))
    ) {
      return
    }

    await restore.performRequest({ json: { ids: selectedRows } })

    if (onOrderModified) {
      onOrderModified()
    }
  }, [onOrderModified, selectedRows, restore])

  const disabled = selectedRows.length === 0
  const isLoading = restore.isLoading

  return (
    <Dropdown>
      <DropdownToggle caret={!isLoading} disabled={disabled}>
        Restore {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickRestore}>Restore...</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default RestoreActionButton
