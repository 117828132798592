import type { ColumnPreset, Columns } from 'components/Table/types'
import { useCallback, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/Dropdown'

const Loader = ({ reload }: { reload: () => void }) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: one shot
  useEffect(() => {
    reload()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

const Item = ({
  preset,
  isSelected,
  onClick,
}: {
  preset: ColumnPreset
  isSelected: boolean
  onClick: (preset: ColumnPreset) => void
}) => {
  const handleClick = useCallback(() => {
    onClick(preset)
  }, [onClick, preset])

  return (
    <DropdownItem onClick={handleClick}>
      <span className={isSelected ? 'font-weight-bold' : ''}>
        {preset.label}
      </span>
    </DropdownItem>
  )
}

type ColumnPresetButtonProps = {
  manageColumnsPanel: { toggle: () => void }
  columns: Columns
}

const ColumnPresetButton = ({
  manageColumnsPanel,
  columns,
}: ColumnPresetButtonProps) => {
  const reload = useCallback(() => {
    columns.apiLoadPresets.performRequest()
  }, [columns.apiLoadPresets])

  const handleClick = useCallback(
    async (preset: ColumnPreset) => {
      columns.setPreset(preset)
    },
    [columns],
  )

  return (
    <Dropdown>
      <DropdownToggle caret hasValue>
        <i className="fa fa-columns" /> {columns.selectedPreset?.label}
      </DropdownToggle>
      <DropdownMenu align="end">
        {columns.presets.map((preset) => {
          return (
            <Item
              isSelected={preset.value === columns.selectedPreset?.value}
              key={preset.value}
              onClick={handleClick}
              {...{ preset }}
            />
          )
        })}
        <DropdownItem onClick={columns.reset}>
          <span className="font-italic">Reset columns</span>
        </DropdownItem>
        <DropdownItem onClick={manageColumnsPanel.toggle}>
          <span className="font-italic">Manage columns...</span>
        </DropdownItem>
        <Loader {...{ reload }} />
      </DropdownMenu>
    </Dropdown>
  )
}

export default ColumnPresetButton
